<template>
  <div class="wrapper">
    <div class="top-header">
      <h1> {{ $t('transport_list_facility') }} </h1>
    </div> 

    <div class="wp-body" v-if="isActive">
      <header class="wp-header">
        <div class="grid grid-cols-12 gap-3 flex-1">
          <div v-if="['admin', 'ministry'].includes(role)" class="col-span-2">
            <el-select class="w-full" v-model="selectedOptions.region" :placeholder="$t('region')"
              @change="regionChanged(); __GET();" clearable>
              <el-option v-for="item in regions" :key="item.id"
                :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div v-if="['admin', 'ministry'].includes(role)" class="col-span-2">
            <el-select v-model="selectedOptions.district" @change="__GET()" :placeholder="$t('district')"
              :disabled="!selectedOptions.region" clearable>
              <el-option v-for="item in districts" :key="item.id"
                :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-span-2 flex items-center gap-2">
            <el-input class="flex-1" :placeholder="$t('search')" prefix-icon="el-icon-search"
              v-model="search_road_number" @keyup.enter.native="__GET()" clearable>
            </el-input>
            <el-button @click="__GET()" type="primary" icon="el-icon-search" size="small" circle></el-button>
          </div>
          <div class="col-span-3 flex items-center gap-2">
            <button class="button dark-blue" @click="fetchOptions()">{{ $t('advanced_filter') }}</button>
          </div>
        </div> 
        <button v-if="['tf_operator'].includes(role)"  class="button dark-yellow" @click="$router.push('/transport/add')" > <i class="el-icon-plus mr-1"></i> {{ $t('transport_create_facility') }}</button>
        <button v-if="['operator'].includes(role) && $route.params.type != 'new'" class="button dark-yellow" @click="$router.push({ name: 'add-build', params: { id: $route.params.id } })"><i class="el-icon-plus mr-1"></i> {{ $t('exist_building_add') }}</button>    
        <button v-if="['ministry'].includes(role) && $route.params.type == 'new'" class="button dark-yellow" @click="$router.push({ name: 'add-build', params: { id: $route.params.id } })"><i class="el-icon-plus mr-1"></i> {{ $t('new_building_add') }}</button>
      </header>
      <el-table :data="resList.results" style="width: 99%;margin-bottom: 20px;" row-key="id">
        <el-table-column label="№" width="70" align="center">
          <template slot-scope="scope">
            {{ (current_page - 1) * item_count + (scope.$index + 1) }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('transport_region_name')" prop="org_name.name_cyr" align="center">
          <template slot-scope="scope">
            <router-link :to="`/transport/show/${scope.row.id}`" class="router-link">{{ scope.row && scope.row.district &&
              scope.row.district.name_cyr ? scope.row.district.name_cyr:'***' }} <i class="el-icon-top-right"></i>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('transport_region_name_number')" prop="road_name.number" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row && scope.row.road_name && scope.row.road_name.number ? scope.row.road_name.number : '***' }}
            </div>
            <div class="road_name">{{ scope.row && scope.row.road_name &&
              scope.row.road_name.name ? scope.row.road_name.name:'***' }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('transport_table_facility_type')" prop="type_transport_facility.name_cyr"
          align="center">
        </el-table-column>
        <el-table-column :label="$t('transport_table_facility_year')" prop="year_construction" align="center">
        </el-table-column>
        <el-table-column :label="$t('transport_table_road_category')" prop="road_category.name_cyr" align="center">
        </el-table-column>
        <el-table-column prop="finalconclusion" :label="$t('finalconclusion')" align="center" sortable>
          <template slot-scope="scope">
            <p
              :class="parseInt(scope.row && scope.row.finalconclusion && scope.row.finalconclusion.id ? scope.row.finalconclusion.id : '***') > 2 ? parseInt(scope.row && scope.row.finalconclusion && scope.row.finalconclusion.id ? scope.row.finalconclusion.id : '***') == 3 ? 'c-yellow' : 'c-red' : 'c-green'">
              {{ scope.row && scope.row.finalconclusion && scope.row.finalconclusion.id ? scope.row.finalconclusion.id :
              '***'}}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="role == 'admin'" :label="$t('passport')" width="180" align="center">
          <template slot-scope="scope">
            <div class="flex justify-center">
              <div>
                <el-button @click="GeneratePasport(scope.row.id)"
                  class="el-icon-s-order el-button-download"></el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('see')" width="100" align="center">
          <template slot-scope="scope">
            <div class="d-flex justify-center">
              <el-button size="small" @click="$router.push(`/transport/show/${scope.row.id}`)"
                class="el-icon-view el-button-download"></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('change')" width="120" align="center" v-if="['tf_operator'].includes(role)">
          <template slot-scope="scope">
            <div class="d-flex justify-center">
              <el-button :disabled="!scope.row.is_user" size="small"
                @click="$router.push(`/transport/edit/${scope.row.id}`)"
                class="el-icon-edit el-button-download"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]"
        :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page"
        :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
      </el-pagination>

      <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction='rtl'>
        <div>
          <div class="pdf">
            <vue-pdf ref="print" :src="pdf"></vue-pdf>
            <div class="loading-pdf" v-show="loadDawer">
              <i class="el-icon-loading"></i>
            </div>
          </div>
          <div class="w-full pdf-control">
            <button class="button dark-yellow" @click="downloadFile(pdf)"><i class="el-icon-download mr-2"></i> {{ $t('download') }}
            </button>
            <a :href="pdf" target="_blank">
              <button class="ml-3 button dark-blue"><i class="el-icon-view mr-2"></i> {{ $t('see') }} </button>
            </a>
          </div>
        </div>
      </el-drawer>

      <!-- FILTERS -->
      <el-dialog class="advanced-filter-dialog" :title="$t('buildings')" :visible.sync="dialogVisible" width="55%"
        style="margin-left: 10px;">
        <div class="flex advanced-filter-input flex-wrap justify-center gap-8">
          <div>
            <div class="flex" style="flex-wrap: wrap; justify-content: space-between;">
              <div style="width: 49%;">
                <label class="block font-medium mt-2 mb-1" style="font-size: 0.84rem;">
                  {{ $t('transport_facility_pass') }}
                </label>

                <el-input v-model="selectedOptions.shifr" :placeholder="$t('transport_facility_pass')" class="" />
              </div>

              <div style="width: 49%;" v-for="(placeholder, index) in placeholders" :key="index">
                <label class="block font-medium mt-2 mb-1" style="font-size: 0.84rem;">
                  {{ $t(placeholder.title) }}
                </label>

                <el-select v-model="selectedOptions[placeholder.value]" class="w-full"
                  :placeholder="$t(placeholder.title)" clearable>
                  <el-option v-for="item in options[index]" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>

              <label style="width: 49%;" class="item">
                <label class="block font-medium mt-2 mb-1" style="font-size: 0.84rem;">
                  {{ $t('way_number')  }}
                </label>
                <el-select class="w-full" v-model="selectedOptions.road_name" filterable remote
                  :placeholder="`${$t('way_number') }*`" :remote-method="remoteMethod" :loading="selLoading">
                  <el-option v-for="(item, i) in options_road_name" :key="i" :label="item.number" :value="item.id">
                  </el-option>
                </el-select>
              </label>

              <div style="width: 49%;">
                <label class="block font-medium mt-2 mb-1" style="font-size: 0.84rem;">
                  {{ $t('year_of_construction') }}
                </label>

                <el-date-picker v-model="selectedOptions.year_construction" :placeholder="$t('year_of_construction')"
                  type="year" value-format="yyyy" class="" filterable style="width: 100%"></el-date-picker>
              </div>

              <label style="width: 49%; justify-content: right" class="item">
                <label class="block font-medium mt-2 mb-1" style="font-size: 0.84rem;">
                  {{ $t('transport_road_name') }}
                </label>
                <el-input class="" disabled v-model="road_name" :placeholder="$t('transport_road_name')" />
              </label>
            </div>
          </div>
        </div>
        <div class="advanced-filter-btn flex w-full justify-end mt-8">
          <div slot="footer" class="dialog-footer">
            <el-button class="advanced-filter" @click="dialogVisible = false">
              {{ $t('cancel') }}
            </el-button>
            <el-button class="advanced-filter" type="primary" @click="__GET(), dialogVisible = false">
              {{ $t('search_btn') }}
            </el-button>
          </div>
        </div>
      </el-dialog>

    </div>
    <div class="wp-body" v-if="!isActive">
      <header class="wp-header">
        <div class="grid grid-cols-12 gap-3 flex-1">
          <div v-if="['admin', 'ministry'].includes(role)" class="col-span-2">
            <el-select class="w-full" :placeholder="$t('region')"  v-model="region"  @change="regionChanged(); railway();" clearable>
                <el-option v-for="item in regions" :key="item.id"
                  :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr" :value="item.id">
                </el-option>
              </el-select>
          </div>
          <div v-if="['admin', 'ministry'].includes(role)" class="col-span-2">
            <el-select class="w-full"  :placeholder="$t('district')"  v-model="district" @change="railway()" :disabled="!region"  clearable>
                <el-option v-for="item in districts" :key="item.id"
                  :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr" :value="item.id">
                </el-option>
              </el-select>
          </div>
          <div class="col-span-2 flex items-center gap-2">
            <el-input class="flex-1" :placeholder="$t('search')"
                prefix-icon="el-icon-search" v-model="search_road_number" @keyup.enter.native="railway()" clearable>
              </el-input>
              <el-button @click="railway()" type="primary" icon="el-icon-search"  size="small"
                circle></el-button>
          </div>
        </div> 
        <button v-if="['tf_operator'].includes(role)"  class="button dark-yellow" @click="$router.push({ path: '/railway/add' })" > <i class="el-icon-plus mr-1"></i> {{ $t('railway_create_facility') }}</button>
        <button v-if="['operator'].includes(role) && $route.params.type != 'new'" class="button dark-yellow" @click="$router.push({ name: 'add-build', params: { id: $route.params.id } })"><i class="el-icon-plus mr-1"></i> {{ $t('exist_building_add') }}</button>    
        <button v-if="['ministry'].includes(role) && $route.params.type == 'new'" class="button dark-yellow" @click="$router.push({ name: 'add-build', params: { id: $route.params.id } })"><i class="el-icon-plus mr-1"></i> {{ $t('new_building_add') }}</button>
      </header>
      <el-table :data="resList.results" style="width: 100%;margin-bottom: 20px;" row-key="id">

        <el-table-column label="№" width="70" align="center">
          <template slot-scope="scope">
            {{ (current_page2 - 1) * item_count2 + (scope.$index + 1) }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('railway_region_name')" prop="region.name_cyr" align="center">
          <template slot-scope="scope">
            <router-link :to="`/railway/show/${scope.row.id}`" class="router-link">
              {{ scope.row && scope.row.region && scope.row.region.name_cyr ? scope.row.region.name_cyr : '***' }}
              <i class="el-icon-top-right"></i>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column :label="$t('transport_region_name')" prop="district.name_cyr" align="center">
          <template slot-scope="scope">
            <!-- <router-link  :to="`/transport/show/${scope.row.id}`" class="router-link">  <i class="el-icon-top-right"></i> </router-link> -->
            {{ scope.row && scope.row.district && scope.row.district.name_cyr ? scope.row.district.name_cyr : '***' }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('railway_route')" prop="railway_line.name" align="center">
          <template slot-scope="scope">
            <!-- <router-link  :to="`/transport/show/${scope.row.id}`" class="router-link">  <i class="el-icon-top-right"></i> </router-link> -->
            {{ scope.row && scope.row.railway_line && scope.row.railway_line.name ? scope.row.railway_line.name : '***' }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('railway_organization_name')" prop="org_name.name_cyr" align="center">
          <template slot-scope="scope">
            <!-- <router-link  :to="`/transport/show/${scope.row.id}`" class="router-link">  <i class="el-icon-top-right"></i> </router-link> -->
            {{ scope.row && scope.row.org_name && scope.row.org_name.name_cyr ? scope.row.org_name.name_cyr : '***' }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('transport_table_facility_year')" prop="construction_year" align="center">
        </el-table-column>

        <el-table-column prop="finalconclusion" :label="$t('finalconclusion')" align="center" sortable>
          <template slot-scope="scope">
            <p
              :class="parseInt(scope.row && scope.row.finalconclusion && scope.row.finalconclusion.id ? scope.row.finalconclusion.id : '***') > 2 ? parseInt(scope.row && scope.row.finalconclusion && scope.row.finalconclusion.id ? scope.row.finalconclusion.id : '***') == 3 ? 'c-yellow' : 'c-red' : 'c-green'">
              {{ scope.row && scope.row.finalconclusion && scope.row.finalconclusion.id ? scope.row.finalconclusion.id :
              '***'}}</p>
          </template>
        </el-table-column>

        <!--            <el-table-column :label="$t('railway_date_filled')" prop="created_at" align="center">-->
        <!--            </el-table-column>-->

        <el-table-column v-if="role == 'admin'" :label="$t('passport')" width="180" align="center">
          <template slot-scope="scope">
            <div class="flex justify-center">
              <div>
                <el-button @click="GeneratePasportRailway(scope.row.id)"
                  class="el-icon-s-order el-button-download"></el-button>
              </div>
            </div>
          </template>
        </el-table-column>

        <!--            <el-table-column :label="$t('see')" width="100" align="center">-->
        <!--                <template slot-scope="scope">-->
        <!--                    <div class="d-flex justify-center">-->
        <!--                        <el-button size="small" @click="$router.push(`/transport/show/${scope.row.id}`)"  class="el-icon-view el-button-download"></el-button>-->
        <!--                    </div>-->
        <!--                </template>-->
        <!--            </el-table-column> -->

        <el-table-column :label="$t('change')" width="120" align="center" v-if="['tf_operator'].includes(role)">
          <template slot-scope="scope">
            <div class="d-flex justify-center">
              <el-button :disabled="!scope.row.is_user" size="small"
                @click="$router.push(`/railway/edit/${scope.row.id}`)"
                class="el-icon-edit el-button-download"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]"
        :page-size="5" @current-change="paginateRailway" @size-change="paginateSizeRailway"
        :current-page.sync="current_page2" :hide-on-single-page="false" background layout="sizes, prev, pager, next"
        :page-count="page_count2">
      </el-pagination>
      <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction='rtl'>
        <div>
          <div class="pdf">
            <vue-pdf ref="print" :src="pdf"></vue-pdf>
            <div class="loading-pdf" v-show="loadDawer">
              <i class="el-icon-loading"></i>
            </div>
          </div>
          <div class="w-full pdf-control">
            <button class="button dark-yellow" @click="downloadFile(pdf)"><i class="el-icon-download mr-2"></i> {{ $t('download') }}
            </button>
            <a :href="pdf" target="_blank">
              <button class="ml-3 button dark-blue"><i class="el-icon-view mr-2"></i> {{ $t('see') }} </button>
            </a>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dialogVisible: false,
      selectedOptions: {},
      placeholders: [
        {
          value: "type_transport_facility",
          title: 'transport_facility_type',
        },
        {
          value: "barrier_type",
          title: "transport_facility_type_barrier",
        },
        {
          value: "type_static_schema",
          title: "transport_facility_type_static_scheme",
        },
        // {
        //   value: "region",
        //   title: "region",
        // },
        // {
        //   value: "district",
        //   title: "district"
        // },
        {
          value: "type_by_length",
          title: "transport_facility_type_length",
        },
        {
          value: "intermediate_device_material",
          title: "transport_device_material",
        },
        {
          value: "buildingseismicity",
          title: "transport_seismicity_construction_site",
        },
        {
          value: "material_of_support",
          title: "transport_material_support",
        },
        {
          value: "base_material",
          title: "transport_base_material",
        },
        {
          value: "repaired",
          title: "transport_facility_repair",
        },
        {
          value: "condition_of_supports",
          title: "transport_facility_basis_changed",
        },
        {
          value: "anti_seismic_struts",
          title: "transport_anti_seismic_struts",
        },
      ],
      options: [],
      dictionary_for_fill: [],
      pdf: '',
      namePdf: '',
      loadDawer: false,
      drawer: false,
      resList: [],
      search: '',
      page_count: 1,
      page_count2: 1,
      loading: false,
      userList: [],
      input2: null,
      items: [{
        message: "Foo"
      }, {
        message: "Bar"
      }],
      currentTab: 1,
      current_page: 1,
      item_count: 10,
      current_page2: 1,
      item_count2: 10,
      districts: [],
      region: "",
      district: "",
      region_shared_id: '',
      search_road_number: '',
      isActive: true,
      form: {
        road_name: ''
      },
      road_name: '',
      options_road_name: [],
      road_name_options: [],
      selLoading: false
    }
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    me() {
      return this.$store.state.me;
    },
    regions() {
      return this.$store.state.dictionary.region;
    },
    dictionary() {
      return this.$store.state.dictionary;
    },
    building_group() {
      return this.$store.state.dictionary.buildinggroup;
    }
  },
  watch: {
    role() {
      this.current_page = this.$route.query.page || 1;
      this.current_page2 = this.$route.query.page || 1;
      this.__GET()
    },
    'selectedOptions.road_name'(val) {
      this.options_road_name.forEach(element => {
        if (element.id == val) {
          this.road_name = element.name
        }
      });

      this.form.road_name_option = ''
      axios.get(`/dictionary/road_name_option/?road_name=${val}`)
        .then((response) => {
          this.road_name_options = response.data.results;
        })
    }
  },
  mounted() {
    this.current_page = this.$route.query.page || 1;
    this.current_page2 = this.$route.query.page || 1;
    this.__GET()
  },
  methods: {
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.selLoading = true
        axios.get(`/dictionary/road_name/?search=${query}`)
          .then((response) => {
            this.options_road_name = response.data.length > 0 ? response.data : []
          })
          .catch(() => {
            this.options_road_name = []
          })
          .finally(() => {
            this.selLoading = false;
          })
      } else {
        this.options_road_name = [];
      }
    },
    fetchOptions() {
      this.dialogVisible = true

      const data = [
        "type_transport_facility",
        "barrier_type",
        "type_static_schema",
        // "region",
        // "district",
        "type_by_length",
        "intermediate_device_material",
        "buildingseismicity",
        "material_of_support",
        "base_material",
        "repaired_type",
        "condition_of_supports",
        "anti_seismic_struts",
      ]

      this.options = []

      data.forEach((option, _) => {
        const arr = []
        this.dictionary[option].forEach(elem => {
          arr.push({
            label: elem.name_cyr || elem.number,
            value: elem.id
          })
        })
        this.options.push(arr)
      })
    },
    regionChanged() {
      this.districts = [];
      this.district = "";
      this.getDistrict();
    },
    getDistrict() {
      this.loading = true;
      this.regions.forEach(elem => {
        if (elem.id === this.region) {
          this.region_shared_id = elem.shared_id
        }
      })
      axios
        .get(`/dictionary/district/?region__shared_id=${this.region_shared_id}`)
        .then((response) => {

          this.districts = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paginate(val) {
      this.current_page = val;
      this.__GET()
    },
    paginateSize(val) {
      this.item_count = val
      this.__GET();
    },
    paginateRailway(val) {
      this.current_page2 = val;
      this.railway()
    },
    paginateSizeRailway(val) {
      this.isActive = true
      this.item_count2 = val
      this.railway();
    },
    __GET() {

      const query = {}

      for (const option in this.selectedOptions) {
        if (this.selectedOptions[option] !== "") {
          query[option] = this.selectedOptions[option]
        }
      }

      this.loading = true;
      let offset = this.item_count * (this.current_page - 1);
      if (this.role == 'admin') {
        axios.get(`/transport_facility/?region=${this.region}&district=${this.district}&road_number=${this.search_road_number}&list=selection&limit=${this.item_count}&offset=${offset}`, {
          params: {
            ...query
          }
        })
          .then((response) => {
            this.resList = response.data;
            this.page_count = Math.ceil(response.data.count / this.item_count);
          })
          .finally(() => {
            this.loading = false;
          })
      } else {
        axios.get(`/transport_facility/?road_number=${this.search_road_number}&limit=${this.item_count}&offset=${offset}`, {
          params: {
            ...query
          }
        })
          .then((response) => {
            this.resList = response.data;
            this.page_count = Math.ceil(response.data.count / this.item_count);
          })
          .finally(() => {
            this.loading = false;
          })
      }

    },
    transport() {

      if (this.isActive == false) {

        this.isActive = true
        this.__GET()

      }

    },
    railway() {

      if (this.isActive == true) {

        this.isActive = false
        this.loading = true;
        let offset = this.item_count2 * (this.current_page2 - 1);
        if (this.role == 'admin') {
          axios.get(`/railway/?region=${this.region}&district=${this.district}&road_number=${this.search_road_number}&list=selection&limit=${this.item_count2}&offset=${offset}`)
            .then((response) => {
              this.resList = response.data;
              this.page_count2 = Math.ceil(response.data.count / this.item_count2);
            })
            .finally(() => {
              this.loading = false;
            })
        } else {
          axios.get(`/railway/?road_number=${this.search_road_number}&limit=${this.item_count2}&offset=${offset}`)
            .then((response) => {
              this.resList = response.data;
              this.page_count2 = Math.ceil(response.data.count / this.item_count2);
            })
            .finally(() => {
              this.loading = false;
            })
        }

      }

    },
    GeneratePasport(id) {
      this.loading = true
      axios.post(`/generate_tf/${id}/`)
        .then(res => {
          if (res.data.success) {
            this.drawer = true
            this.loadDawer = true
            this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
          }

        })
        .catch(e => {

        })
        .finally(() => {
          this.loading = false

          this.loadDawer = false
        })
    },
    GeneratePasportRailway(id) {
      this.loading = true
      axios.post(`/generate_railway/${id}/`)
        .then(res => {
          if (res.data.success) {
            this.drawer = true
            this.loadDawer = true
            this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link
          }

        })
        .catch(e => {

        })
        .finally(() => {
          this.loading = false

          this.loadDawer = false
        })
    },
    downloadFile(file) {
      fetch(file)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = 'Pasport' || "file.json";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.road_name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
